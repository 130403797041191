module.exports = {
  siteTitle: 'simorq',
  siteDescription: 'we dream, we build',
  siteKeywords: 'simorq',
  siteUrl: 'https://simorq.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: '216383042',
  googleVerification: '',
  name: 'simorq',
  location: '39 Khaqani, Zargandeh, Tehran',
  email: 'hi@simorq.com',
  github: '',
  twitterHandle: '',
  socialMedia: [
    // {
    //   name: 'Github',
    //   url: '',
    // },
    // {
    //   name: 'Linkedin',
    //   url: 'https://linkedin.com/in/sajadsadeh-02309b65',
    // }
    // , {
    //   name: 'Skype',
    //   url: 'https://join.skype.com/invite/av7V2PTgCX1O',
    // }
    // ,
    // {
    //   name: 'Instagram',
    //   url: '',
    // },
    // {
    //   name: 'Twitter',
    //   url: '',
    // },
  ],

  navLinks: [
    // {
    //   name: 'About',
    //   url: '#about',
    // },
    // {
    //   name: 'Experience',
    //   url: '#jobs',
    // },
    // {
    //   name: 'Work',
    //   url: '#projects',
    // },
    // {
    //   name: 'Contact',
    //   url: '#contact',
    // },
  ],

  navHeight: 100,
  // greenColor: '#64ffda',
  greenColor: '#7f735c',
  navyColor: '#0a192f',
  darkNavyColor: '#020c1b',

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
